export const chartTypes = {
  LoC: 'loc',
  Milestones: 'milestones',
  EstimationCoverage: 'estimationCoverage',
  EstimationAccuracy: 'estimationAccuracy',
  BillableTime: 'billableTime',
  ReopenedTickets: 'reopenedTickets',
  Workload: 'workload',
  UserActivity: 'userActivity',
  Activities: 'activities',
  ProjectAllocation: 'projectAllocation',
  VelocityFactor: 'velocityFactor',
  VelocityFactorPerDeveloper: 'velocityFactorPerDeveloper',
  Burndown: 'burndown',
  ScopeVsDelivered: 'scopeVsDelivered',
  BudgetPlannedVsUsed: 'budgetPlannedVsUsed',
  GlobalLocAverages: 'globalLocAverages',
  LargestTicketsByEstimation: 'largestTickets',
  ReleaseTickets: 'releaseTickets',
  StuckTickets: 'stuckTickets',
  BugMetrics: 'bugMetrics',
  CommentsRates: 'commentsRates',
}

export const chartTitle = {
  [chartTypes.LoC]: 'Lines of code',
  [chartTypes.Milestones]: 'Milestones',
  [chartTypes.EstimationCoverage]: 'Estimation coverage',
  [chartTypes.EstimationAccuracy]: 'Estimation accuracy',
  [chartTypes.BillableTime]: 'Billable time',
  [chartTypes.ReopenedTickets]: 'Reopened tickets',
  [chartTypes.Workload]: 'Workload',
  [chartTypes.UserActivity]: 'Activities',
  [chartTypes.Activities]: 'Activities',
  [chartTypes.ProjectAllocation]: 'Project allocation',
  [chartTypes.VelocityFactor]: 'Project velocity factor',
  [chartTypes.VelocityFactorPerDeveloper]: 'Velocity factor',
  [chartTypes.Burndown]: 'Burndown',
  [chartTypes.ScopeVsDelivered]: 'Scope vs Delivered',
  [chartTypes.BudgetPlannedVsUsed]: 'Budget planned vs used',
  [chartTypes.GlobalLocAverages]: 'Global LOC averages',
  [chartTypes.LargestTicketsByEstimation]: '10 largest tickets by estimation',
  [chartTypes.StuckTickets]: 'Stuck tickets',
  [chartTypes.BugMetrics]: 'Bug metrics',
  [chartTypes.CommentsRates]: 'Comments rates',
  [chartTypes.ReleaseTickets]: 'Tickets',
}

export const chartExplanations = {
  [chartTypes.LoC]: `Showing code changes based on commits with optionally showing a total average for the time frame and for all time.

    We do not count empty lines. 
    Duplicates are only checked within commits, not across all commits.
    Duplicates are excluded from Insertions.

    The all average shows all time average (i.e. ignore time filter), but it considers project and user filters. It is not divided by user number though.  
  `,
  [chartTypes.Milestones]: `This chart shows time allocated to projects (bars) and releases per year period`,
  [chartTypes.Workload]: `This graph shows the total hours logged in Tempo divided by the total hours allocated in Tempo workload for the selected time frame.`,
  [chartTypes.BillableTime]: `This graph shows the total hours logged on billable account in the selected time frame divided by the total hours logged.`,
  [chartTypes.UserActivity]: `Showing Merge Requests, their added comments as Notes and Commits, which are not included in Merge Requests.`,
  [chartTypes.Activities]: `Showing Merge Requests, their added comments as Notes and Commits, which are not included in Merge Requests.`,
  [chartTypes.EstimationCoverage]: `This graph shows the total assigned tickets estimated in Done status category divided by the total number of assigned tickets in the same category with an estimation.`,
  [chartTypes.EstimationAccuracy]: `This graph shows the hours logged on tickets in Done status category divided by the total estimations on the same tickets per user.`,
  [chartTypes.EstimationAccuracy +
  'PerProject']: `This graph shows the hours logged on tickets in Done status category divided by the total estimations on the same tickets per project.`,
  [chartTypes.ReopenedTickets]: `This graph shows the number of assigned tickets which had a Reopened status in the time frame divided by the total number of assigned ticket with a Done status.`,
  [chartTypes.ProjectAllocation]: `This graph shows the total hours logged from all users per DevFlow project divided by the overall total hours logged from all users.`,
  [chartTypes.ScopeVsDelivered]: `This chart is showing the total estimations as the red line for tasks in To Do, In Progress and Done categories. The total estimations of tasks as the blue line at a given time point in the In Progress category. The total estimations of tasks as the green line at a given time point in the Done category.

  Any work done before the selected time frame will be deducted from the In Progress and Done amounts.`,
  [chartTypes.BudgetPlannedVsUsed]: `This chart is showing the defined budget(s) for a project based on the allocated date as the red line. The planned Tempo hours multiplied by the defined role rates as the blue line. The logged Tempo hours multiplied by the defined role rates as the blue line. The budget and role rates can be modified on the mapping page by any admin user.`,
  [chartTypes.Burndown]: `This chart shows the total remaining time of tasks that are in To Do or In Progress status categories as the red line. The total logged hours of tasks in the To Do, In Progress and Done status as the blue line.`,
  [chartTypes.VelocityFactor]: `This value takes the total estimation of tasks for the checked project that were moved to the Done category in the selected time frame divided by the total logged hours.`,
  [chartTypes.VelocityFactorPerDeveloper]: `This value takes the total estimation of tasks of checked developers for the checked project that were moved to the Done category in the selected time frame divided by the total logged hours regardless of project.`,
  [chartTypes.LargestTicketsByEstimation]: `This table shows a simple list of all Jira tickets arranged in a decreasing order by estimation amount.`,
  [chartTypes.StuckTickets]: `List of tickets waiting the longest in a sprint/kanban column.`,
  [chartTypes.BugMetrics]: `Amount of old bugs (found in the past) and fixed this week. Amount of bugs found and fixed during the week. Amount of unresolved old bugs (found in the past) and amount of unresolved new bugs (found this week)`,
  [chartTypes.CommentsRates]: `This graph shows the number of comments per rate per user in the selected time frame. Rated by chatGPT`,
  [chartTypes.ReleaseTickets]:
    'This table shows a simple list of all Jira tickets of selected milestone',
}
