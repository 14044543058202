<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="close"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        <router-link
          v-if="!projectPage"
          :to="`/company/${store.getters['company/selectedCompanyId']}/projects?projects=${milestoneData?.project_id}`"
          class="text-key-500 font-bold"
        >
          {{ milestoneData?.project_name }}
        </router-link>
        <span v-else class="font-bold">{{ milestoneData?.project_name }}</span>
        • Milestone
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">
      {{ milestoneData?.release_name }}
    </div>
    <div class="flex gap-16 mb-8">
      <div class="loader-height w-full flex" v-if="loading">
        <LoaderWrapper></LoaderWrapper>
      </div>
      <div v-if="!loading" class="w-2/3">
        <TicketsTable
          :filters="ticketsFilters"
          popup
          project-page
          :type="chartTypes.ReleaseTickets"
        ></TicketsTable>
        <Divider class="my-8" />
        <BurndownChart
          :filters="burndownFilters"
          project-page
          milestone-popup
          :release-date="milestoneInfo?.release_date"
        ></BurndownChart>
      </div>
      <div v-if="!loading" class="w-1/3">
        <div class="font-semi-bold mb-4">Details</div>
        <PopupBlock title="Progress" class="mb-1">
          <ProgressBar
            :value="amountOfDoneTickets / amountOfAllTickets"
            :label="`${amountOfDoneTickets} of ${amountOfAllTickets} tickets done`"
          >
          </ProgressBar>
        </PopupBlock>
        <PopupBlock title="Deadline" class="mb-1">
          <div>
            {{
              milestoneInfo?.release_date
                ? format(
                    new Date(milestoneInfo?.release_date || null),
                    'MMMM dd, yyyy'
                  )
                : 'Not specified'
            }}
          </div>
        </PopupBlock>
        <PopupBlock title="Contributors" class="mb-8">
          <ul class="flex flex-col gap-2">
            <li
              v-for="contributor in milestoneAdditionalInfo?.assignees"
              :key="contributor?.original_name"
              class="flex items-center gap-2"
            >
              <Avatar
                :url="contributor?.avatar_url"
                :label="contributor?.original_name"
                size="small"
              ></Avatar>
              <span>{{ contributor?.original_name }}</span>
            </li>
          </ul>
        </PopupBlock>
        <div class="flex items-center justify-between mb-4">
          <div class="font-semi-bold">Note</div>
          <Button
            class="save-button"
            v-if="editMode"
            size="small"
            @click="saveNote"
            >Save</Button
          >
          <IconButton
            v-if="!editMode && note && hasUserAccessToEditRelease"
            icon="pi-pencil"
            icon-position="left"
            icon-size="12px"
            class="p-button-outlined edit-button"
            size="small"
            @click="editMode = true"
            >Edit</IconButton
          >
        </div>
        <BaseTextarea
          v-if="!milestoneInfo?.note && hasUserAccessToEditRelease"
          placeholder="Leave your note"
          v-model:value="note"
          @input="editMode = true"
        ></BaseTextarea>
        <PopupBlock v-else title="">
          <BaseTextarea
            v-if="editMode"
            placeholder="Leave your note"
            v-model:value="note"
          ></BaseTextarea>
          <div v-else>{{ milestoneInfo?.note || 'Not added' }}</div>
        </PopupBlock>
      </div>
      <CancelEditConfirmationDialog
        v-model:open="showCancelEditDialog"
        @continue="closePopup"
      ></CancelEditConfirmationDialog>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import Dialog from 'primevue/dialog/Dialog.vue'
import { Filters } from '@/store/modules/filters'
import BurndownChart from '@/components/charts/burndown/BurndownChart.vue'
import { format } from 'date-fns'
import PopupBlock from '@/components/charts/milestones/PopupBlock.vue'
import BaseTextarea from '@/components/common/base/BaseTextarea.vue'
import ProgressBar from '@/components/common/ProgressBar.vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import { showToastError } from '@/utils/utils'
import { useStore } from '@/store'
import { useToast } from 'primevue/usetoast'
import {
  MilestoneAdditionalInfo,
  MilestoneInfo,
} from '@/store/modules/charts/milestones'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import CancelEditConfirmationDialog from '@/components/common/dialogs/CancelEditConfirmationDialog.vue'
import TicketsTable from '@/components/charts/largest-tickets-by-estimation/TicketsTable.vue'
import { chartTypes } from '@/constants/charts/constants'
import Avatar from '@/components/common/Avatar.vue'
import { omit } from 'ramda'
import { USER_ROLES } from '@/constants/constants'

const props = defineProps<{
  open: boolean
  projectPage: boolean
  milestoneData: {
    release_name: string
    project_id: number
    project_name: string
    release_id: number
    filters: Filters
  } | null
}>()
const emit = defineEmits<{ (e: 'close'): void }>()

const milestoneInfo = ref<MilestoneInfo | null>(null)
const milestoneAdditionalInfo = ref<MilestoneAdditionalInfo | null>(null)
const note = ref<string | null>(null)
const editMode = ref(false)
const showCancelEditDialog = ref(false)
const loading = ref(false)
const store = useStore()
const toast = useToast()
const releaseDate = ref(null)

const hasUserAccessToEditRelease = computed(() =>
  [USER_ROLES.ADMIN, USER_ROLES.OWNER].includes(
    store.getters['company/userRole']
  )
)

const amountOfDoneTickets = computed(
  () => milestoneAdditionalInfo.value?.issues_stats?.count_done || 0
)

const amountOfAllTickets = computed(
  () => milestoneAdditionalInfo.value?.issues_stats?.count_all || 0
)

const ticketsFilters = computed(() => {
  return {
    ...props.milestoneData?.filters,
    releases: [props.milestoneData?.release_id],
  }
})

const burndownFilters = computed(() => {
  return {
    ...omit(['since', 'until'], props.milestoneData?.filters),
    scale_type: 'date',
    releases: [props.milestoneData?.release_id],
  }
})

const getMilestone = async () => {
  try {
    if (props.milestoneData) {
      const milestone = await store.dispatch(
        'milestones/getMilestone',
        props.milestoneData.release_id
      )
      milestoneInfo.value = milestone
      note.value = milestone.note
      if (hasUserAccessToEditRelease.value) {
        editMode.value = !milestone.note
      }
      releaseDate.value = milestone.release_date
    }
  } catch (e) {
    showToastError(toast, e)
  }
}

const getMilestoneAdditionalInfo = async () => {
  try {
    if (props.milestoneData) {
      milestoneAdditionalInfo.value = await store.dispatch(
        'milestones/getMilestoneAdditionalInfo',
        {
          release_id: props.milestoneData.release_id,
          project_id: props.milestoneData.project_id,
        }
      )
    }
  } catch (e) {
    showToastError(toast, e)
  }
}

watch(
  () => props.milestoneData,
  async () => {
    if (props.milestoneData) {
      loading.value = true
      await Promise.all([getMilestone(), getMilestoneAdditionalInfo()]).then(
        () => {
          loading.value = false
        }
      )
    } else {
      milestoneInfo.value = null
      note.value = null
    }
  }
)

const saveNote = async () => {
  try {
    milestoneInfo.value = await store.dispatch('milestones/updateMilestone', {
      id: props.milestoneData?.release_id,
      note: note.value,
    })
    editMode.value = false
  } catch (e) {
    showToastError(toast, e)
  }
}

const close = () => {
  if (editMode.value && note.value) {
    showCancelEditDialog.value = true
  } else {
    closePopup()
  }
}

const closePopup = () => {
  milestoneInfo.value = null
  editMode.value = false
  showCancelEditDialog.value = false
  note.value = null
  releaseDate.value = null
  emit('close')
}
</script>

<style scoped lang="scss">
.loader-height {
  height: 70vh;
}
</style>
