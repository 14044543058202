import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { isEmpty } from 'ramda'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { getFirstDayOfWeekOrMonth } from '@/utils/date-utils'
import { isNotEmpty } from 'ramda-adjunct'
import { format } from 'date-fns'

export interface Budget {
  date: string
  budget?: number
  hideDot?: boolean
}

export interface BudgetPlannedVsUsed {
  budget: Budget[]
  used_budget: Budget[]
  planned_budget: Budget[]
}

type Context = ActionContext<BudgetPlannedVsUsed, GlobalState>

const actions = {
  async getData(
    { rootGetters }: Context,
    params: {
      filters: Filters
      project_id: number
      milestonePopup: boolean
    }
  ): Promise<BudgetPlannedVsUsed | []> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.project_id}/charts/budget/`,
        {
          params: params.filters,
        }
      )
      .then(({ data: initialData }) => {
        const originalData = Object.keys(initialData).reduce(
          (acc, key: keyof typeof initialData) =>
            isNotEmpty(acc[key]) ? { ...acc, [key]: initialData[key] } : acc,
          {} as typeof initialData
        )
        const firstOriginalDate = Object.keys(originalData)
          .map((key) => originalData[key][0]?.date)
          .filter(Boolean)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]

        const lastOriginalDate = Object.keys(originalData)
          .map(
            (key) =>
              isNotEmpty(originalData[key]) &&
              originalData[key]?.[originalData[key].length - 1]?.date
          )
          .filter(Boolean)
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]

        if (!firstOriginalDate || !lastOriginalDate) {
          return {
            budget: [],
            used_budget: [],
            planned_budget: [],
          }
        }

        let firstDate
        if (params.filters.since) {
          firstDate = getFirstDayOfWeekOrMonth(
            new Date(params.filters.since),
            params.filters.scale_type
          )
        } else if (params.milestonePopup && params.filters.until) {
          firstDate = getFirstDayOfWeekOrMonth(
            new Date(params.filters.until),
            params.filters.scale_type
          )
        } else {
          firstDate = firstOriginalDate
        }

        const lastDate = params.filters.until
          ? format(new Date(params.filters.until), 'yyyy-MM-dd')
          : lastOriginalDate

        const firstWeekOfRange = {
          date: firstDate,
        }

        const lastWeekOfRange = {
          date: lastDate,
        }

        const beforeRange: Budget[] = []
        let inTheRange: Budget[] = []

        originalData.budget.forEach((item: Budget) => {
          if (
            item.date >= firstWeekOfRange.date &&
            item.date <= lastWeekOfRange.date
          ) {
            inTheRange.push(item)
          } else if (item.date < firstWeekOfRange.date) {
            beforeRange.push(item)
          }
        })

        if (isEmpty(beforeRange) && isEmpty(inTheRange)) {
          return {
            ...originalData,
            budget: [],
          }
        }

        const updateInTheRangeArrayWithFirstAndLastWeek = () => {
          if (
            inTheRange[0].date !== firstWeekOfRange.date &&
            !!beforeRange.length
          ) {
            inTheRange = [
              {
                date: firstWeekOfRange.date,
                budget: beforeRange[beforeRange.length - 1].budget,
                hideDot: true,
              },
              ...inTheRange,
            ]
          }
          if (inTheRange[inTheRange.length - 1].date !== lastWeekOfRange.date) {
            inTheRange.push({
              date: lastWeekOfRange.date,
              budget: inTheRange[inTheRange.length - 1].budget,
              hideDot: true,
            })
          }
          return inTheRange
        }
        return {
          ...originalData,
          budget: isEmpty(inTheRange)
            ? [
                {
                  date: firstWeekOfRange.date,
                  budget: beforeRange[beforeRange.length - 1].budget,
                  hideDot: true,
                },
                {
                  date: lastWeekOfRange.date,
                  budget: beforeRange[beforeRange.length - 1].budget,
                  hideDot: true,
                },
              ]
            : updateInTheRangeArrayWithFirstAndLastWeek(),
        }
      })
  },
}

export default {
  namespaced: true,
  actions,
}
