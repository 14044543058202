<template>
  <div class="h-full base-container">
    <FiltersBar
      :filters="filters"
      :filtering-options="filterItems"
    ></FiltersBar>
    <main>
      <div class="flex flex-wrap px-8 mb-8">
        <div class="flex w-1/2">
          <BillableTimeChart :filters="filters" :key="chartKey" />
          <Divider layout="vertical"></Divider>
        </div>
        <WorkloadChart :key="chartKey" :filters="filters"></WorkloadChart>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <LOCChart :filters="filters" :key="chartKey"></LOCChart>
          <Divider layout="vertical"></Divider>
        </div>
        <CommentsRatesChart
          :filters="filters"
          :key="chartKey"
        ></CommentsRatesChart>
        <Divider class="my-8" />
        <div class="flex w-full">
          <UserActivityChart
            :filters="filters"
            :key="chartKey"
          ></UserActivityChart>
        </div>
        <Divider class="my-8" />

        <div class="flex w-1/2">
          <EstimationCoverageChart
            :filters="filters"
            :key="chartKey"
          ></EstimationCoverageChart>
          <Divider layout="vertical"></Divider>
        </div>
        <EstimationAccuracyChart
          :filters="filters"
          :key="chartKey"
        ></EstimationAccuracyChart>
        <Divider class="my-8" />
        <div class="flex w-1/2">
          <ReopenedTicketsChart
            :filters="filters"
            :key="chartKey"
          ></ReopenedTicketsChart>
          <Divider layout="vertical"></Divider>
        </div>
        <VelocityFactorTable
          :filters="filters"
          :key="chartKey"
        ></VelocityFactorTable>
        <Divider class="my-8" />
        <div class="flex w-full">
          <Activities
            :filters="filters"
            :key="chartKey"
            local-time-aggregation
          ></Activities>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
import FiltersBar from '@/components/common/menu/TeamFiltersBar.vue'
import { useStore } from '@/store'
import { computed } from 'vue'
import LOCChart from '@/components/charts/loc/LOCChart.vue'
import WorkloadChart from '@/components/charts/workload/WorkloadChart.vue'
import BillableTimeChart from '@/components/charts/billable-time/BillableTimeChart.vue'
import UserActivityChart from '@/components/charts/user-activity/UserActivityChart.vue'
import EstimationCoverageChart from '@/components/charts/estimation-coverage/EstimationCoverageChart.vue'
import EstimationAccuracyChart from '@/components/charts/estimation-accuracy/EstimationAccuracyChart.vue'
import ReopenedTicketsChart from '@/components/charts/reopened-tickets/ReopenedTicketsChart.vue'
import VelocityFactorTable from '@/components/charts/velocity-factor/VelocityFactorTable.vue'
import CommentsRatesChart from '@/components/charts/comments-rates/CommentsRatesChart.vue'
import Activities from '@/components/charts/user-activity/Activities.vue'

const store = useStore()
const filters = computed(() => store.state.filters.teamFilters)
const filterItems = computed(() => store.getters['filters/filterItems'])
const chartKey = computed(() => JSON.stringify(filters.value))
</script>

<style lang="scss" scoped>
.base-container {
  max-height: 100vh;
  flex-grow: 1 !important;
  overflow: auto;
  padding-bottom: 32px;
}
</style>
