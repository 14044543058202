<template>
  <Editor
    :model-value="value"
    editorStyle="height: 20vh; maxHeight: 300px"
    @update:modelValue="emit('update:value', $event)"
    @paste="onPaste"
  >
    <template v-slot:toolbar>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-link"></button>
      </span>
    </template>
  </Editor>
</template>

<script setup lang="ts">
import Editor from 'primevue/editor'
import { defineProps, defineEmits } from 'vue'

defineProps<{ value: string | null }>()
const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>()

const onPaste = (e: ClipboardEvent) => {
  e.preventDefault()
  const html = e.clipboardData
    ?.getData('text/html')
    .replace(/background-color:\s[^;]+;/g, '')
  const text = html || e.clipboardData?.getData('text/plain')

  if (text) {
    const selection = window.getSelection()
    if (!selection?.rangeCount) return

    selection.deleteFromDocument()
    const range = selection.getRangeAt(0)
    const fragment = range.createContextualFragment(text)
    range.insertNode(fragment)
  }
}
</script>
