<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="close"
    :position="calendarWeekData?.dialog_position || 'bottom'"
    modal
    class="calendar-week-popup"
  >
    <template #header>
      <div class="w-full flex items-center justify-between">
        <div>
          <router-link
            v-if="!projectPage"
            :to="`/company/${store.getters['company/selectedCompanyId']}/projects?projects=${calendarWeekData?.project_id}`"
            class="text-key-500 font-bold"
          >
            {{ calendarWeekData?.project_name || 'Project name' }}
          </router-link>
          <span v-else class="font-bold">
            {{ calendarWeekData?.project_name || 'Project name' }}
          </span>
          • Calendar week
        </div>
        <div
          v-if="!projectPage && selectedProjects.length > 1"
          class="flex items-center gap-2"
        >
          <IconButton class="p-button-text" @click="openPrevProject"
            ><i class="pi pi-arrow-left"></i
          ></IconButton>
          <IconButton class="p-button-text" @click="openNextProject"
            ><i class="pi pi-arrow-right"></i
          ></IconButton>
        </div>
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">{{ title }}</div>
    <div class="flex gap-16 mb-8">
      <div class="loader-height w-full flex" v-if="loading">
        <LoaderWrapper></LoaderWrapper>
      </div>
      <div v-if="!loading" class="w-2/3">
        <MilestonesTable
          :filters="{
            ...calendarWeekData?.filters,
          }"
          project-page
          popup
          :key="originalReport"
          :selected-week-date="calendarWeekData?.date"
          @close-calendar-week-popup="openFollowUpPopup"
        >
        </MilestonesTable>
        <Divider class="my-8" />
        <BudgetPlannedVsUsedChart
          :filters="chartsFilters"
          project-page
          milestone-popup
        >
        </BudgetPlannedVsUsedChart>
        <Divider class="my-8" />
        <ScopeVsDeliveredChart
          :filters="{ ...chartsFilters, scale_type: 'week' }"
          project-page
          milestone-popup
        ></ScopeVsDeliveredChart>
      </div>
      <div v-if="!loading" class="w-1/3">
        <div class="flex items-center justify-between mb-4">
          <div class="font-semi-bold">Details</div>
          <div v-if="editMode" class="flex gap-2">
            <Button
              class="p-button-outlined cancel-button"
              size="small"
              @click="cancelEditing"
              >Cancel</Button
            >
            <Button class="save-button" size="small" @click="saveReport"
              >Save</Button
            >
          </div>
          <IconButton
            v-else-if="hasUserAccessToEditReport"
            icon="pi-pencil"
            icon-position="left"
            icon-size="12px"
            class="p-button-outlined edit-button"
            size="small"
            @click="editMode = true"
            >Edit</IconButton
          >
        </div>
        <PopupBlock title="RAG Status" class="mb-1">
          <BaseDropdown
            v-if="editMode"
            v-model:selected="formData.status"
            :options="statusesOptions"
          >
            <template #value="slotProps">
              <ColorOption :value="slotProps.slotProps.value"></ColorOption>
            </template>
            <template #option="slotProps">
              <ColorOption :value="slotProps.slotProps.option.id"></ColorOption>
            </template>
          </BaseDropdown>
          <ColorOption v-else :value="formData.status"></ColorOption>
        </PopupBlock>
        <PopupBlock title="Description" class="mb-1">
          <BaseEditor
            v-if="editMode"
            :value="formData.description"
            @update:value="updateDescription"
          ></BaseEditor>
          <div
            v-else
            v-html="formData.description || 'Not added'"
            class="editor-text"
          ></div>
        </PopupBlock>
        <PopupBlock title="Budget remarks" class="mb-1">
          <BaseEditor
            v-if="editMode"
            :value="formData.budget"
            @update:value="updateBudget"
          ></BaseEditor>
          <div
            v-else
            v-html="formData.budget || 'Not added'"
            class="editor-text"
          ></div>
        </PopupBlock>
        <PopupBlock title="Phase" class="mb-1">
          <BaseDropdown
            v-if="editMode"
            v-model:selected="formData.phase"
            :options="phaseOptions"
          ></BaseDropdown>
          <div v-else>
            {{
              phaseOptions.find((opt: PhaseOption) => opt.id === formData.phase)
                ?.name || ''
            }}
          </div>
        </PopupBlock>
        <PopupBlock title="Project Manager" class="mb-1">
          <div class="flex items-center gap-2">
            <Avatar
              v-if="formData.lead?.avatar_url || formData.lead?.original_name"
              :url="formData.lead?.avatar_url"
              :label="formData.lead?.original_name"
              size="small"
            ></Avatar>
            <span>{{ formData.lead?.original_name || 'Not added' }}</span>
          </div>
        </PopupBlock>
      </div>
      <CancelEditConfirmationDialog
        v-model:open="showCancelEditDialog"
        @continue="closePopup"
      ></CancelEditConfirmationDialog>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import Dialog from 'primevue/dialog/Dialog.vue'
import {
  format,
  getISOWeek,
  startOfISOWeek,
  lastDayOfISOWeek,
  endOfISOWeek,
} from 'date-fns'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import { Filters } from '@/store/modules/filters'
import ScopeVsDeliveredChart from '@/components/charts/scope-vs-delivered/ScopeVsDeliveredChart.vue'
import BudgetPlannedVsUsedChart from '@/components/charts/budget-planned-vs-used/BudgetPlannedVsUsedChart.vue'
import PopupBlock from '@/components/charts/milestones/PopupBlock.vue'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import ColorOption from '@/components/charts/milestones/ColorOption.vue'
import { useStore } from '@/store'
import {
  FILTERS_DATE_FORMAT,
  MILESTONE_POPUPS,
  MILESTONE_WEEK_PHASE,
  MILESTONE_WEEK_STATUS,
  USER_ROLES,
} from '@/constants/constants'
import { PopupType, WeekReport } from '@/store/modules/charts/milestones'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import CancelEditConfirmationDialog from '@/components/common/dialogs/CancelEditConfirmationDialog.vue'
import { Project } from '@/store/modules/admin/admin'
import Avatar from '@/components/common/Avatar.vue'
import BaseEditor from '@/components/common/BaseEditor.vue'
import {
  CalendarWeekPopupData,
  MilestonePopupData,
  PhaseOption,
} from './interfaces'
import MilestonesTable from './MilestonesTable.vue'

const props = defineProps<{
  open: boolean
  projectPage: boolean
  calendarWeekData: {
    date: string
    project_id: number
    project_name: string
    filters: Filters
    dialog_position: 'left' | 'right' | 'center'
  } | null
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'reload-milestone'): void
  (e: 'toggle-popups', value: CalendarWeekPopupData): void
}>()

const initialForm = () => ({
  status: MILESTONE_WEEK_STATUS.GREEN,
  description: '',
  budget: '',
  phase: MILESTONE_WEEK_PHASE.DEVELOPMENT,
  lead: { original_name: null, avatar_url: null },
})

const store = useStore()
const toast = useToast()
const loading = ref(false)
const editMode = ref(false)
const showCancelEditDialog = ref(false)

const hasUserAccessToEditReport = computed(() =>
  [USER_ROLES.ADMIN, USER_ROLES.OWNER].includes(
    store.getters['company/userRole']
  )
)

const selectedProjects = computed(() => {
  const selected = store.state.admin.original_projects.filter(
    (project: Project) =>
      !!store.state.filters.portfolioFilters.projects?.includes(
        String(project.id)
      )
  )
  return selected.length ? selected : store.state.admin.original_projects
})

const indexOfCurrentProject = computed(() =>
  selectedProjects.value.findIndex(
    (project: Project) => project.id === props.calendarWeekData?.project_id
  )
)
const followUpPopupData = ref<{
  popup: PopupType
  data: MilestonePopupData | CalendarWeekPopupData
} | null>(null)
const isStatusUpdated = ref(false)

const formData = ref<Partial<WeekReport> | null>(initialForm())
const originalReport = ref<WeekReport | null>(null)

const chartsFilters = computed(() => {
  return {
    ...props.calendarWeekData?.filters,
    until: props.calendarWeekData?.date
      ? format(
          endOfISOWeek(new Date(props.calendarWeekData?.date)),
          FILTERS_DATE_FORMAT
        )
      : null,
    since: null,
    scale_type: 'date',
  }
})

watch(
  () => props.calendarWeekData,
  async () => {
    if (props.calendarWeekData) {
      try {
        loading.value = true
        const report = await store.dispatch(
          'milestones/getCalendarWeekReport',
          {
            project: String(props.calendarWeekData?.project_id),
            date: props.calendarWeekData?.date,
          }
        )
        if (report) {
          originalReport.value = report
          formData.value = { ...originalReport.value }
          loading.value = false
        }
      } catch (e) {
        showToastError(toast, e)
        loading.value = false
      }
    } else {
      originalReport.value = null
      formData.value = initialForm()
    }
  }
)

const title = computed(() => {
  const date = props.calendarWeekData?.date
  if (!date) return ''
  const week = getISOWeek(new Date(date))
  const firstDayOfWeek = startOfISOWeek(new Date(date))
  const lastDayOfWeek = lastDayOfISOWeek(new Date(date))

  const monthOfFirstDayOfWeek = format(firstDayOfWeek, 'MMMM')
  const monthOfLastDayOfWeek = format(lastDayOfWeek, 'MMMM')
  return `CW${week}, ${monthOfFirstDayOfWeek} ${format(
    firstDayOfWeek,
    'd'
  )} - ${
    monthOfLastDayOfWeek === monthOfFirstDayOfWeek ? '' : monthOfLastDayOfWeek
  } ${format(lastDayOfWeek, 'd')}`
})

const statusesOptions = [
  { name: 'Green', id: MILESTONE_WEEK_STATUS.GREEN },
  { name: 'Yellow', id: MILESTONE_WEEK_STATUS.YELLOW },
  { name: 'Red', id: MILESTONE_WEEK_STATUS.RED },
]

const phaseOptions: PhaseOption[] = [
  { name: 'Development', id: MILESTONE_WEEK_PHASE.DEVELOPMENT },
  { name: 'Maintenance', id: MILESTONE_WEEK_PHASE.MAINTENANCE },
  { name: 'On hold', id: MILESTONE_WEEK_PHASE.ON_HOLD },
]

const updateDescription = (ev: string) => {
  formData.value = {
    ...formData.value,
    description: ev,
  }
}

const updateBudget = (ev: string) => {
  formData.value = {
    ...formData.value,
    budget: ev,
  }
}

const saveReport = async () => {
  try {
    const report = await store.dispatch('milestones/updateCalendarWeekData', {
      ...formData.value,
    })
    if (originalReport.value?.status !== formData.value?.status) {
      isStatusUpdated.value = true
    }
    originalReport.value = { ...report }
    editMode.value = false
  } catch (e) {
    showToastError(toast, e)
  }
}

const cancelEditing = () => {
  formData.value = { ...originalReport.value }
  editMode.value = false
}

const close = () => {
  if (editMode.value) {
    showCancelEditDialog.value = true
  } else {
    closePopup()
  }
}

const closePopup = () => {
  formData.value = initialForm()
  originalReport.value = null
  editMode.value = false
  showCancelEditDialog.value = false
  if (followUpPopupData.value) {
    emit('toggle-popups', followUpPopupData.value)
  }
  followUpPopupData.value = null
  emit('close')
  if (isStatusUpdated.value) {
    emit('reload-milestone')
  }
}

const openFollowUpPopup = (value: {
  popup: PopupType
  data: MilestonePopupData | CalendarWeekPopupData
}) => {
  followUpPopupData.value =
    value.popup === MILESTONE_POPUPS.CALENDAR_WEEK &&
    !value.data.dialog_position
      ? { ...value, data: { ...value.data, dialog_position: 'center' } }
      : value
  if (editMode.value) {
    showCancelEditDialog.value = true
  } else {
    closePopup()
  }
}

const switchToAnotherProjectCalendarWeekPopup = (
  index: number,
  position: 'left' | 'right'
) => {
  const calendarData = {
    ...props.calendarWeekData,
    filters: {
      ...(props.calendarWeekData?.filters ?? {}),
      projects: [selectedProjects.value[index].id],
    },
    project_id: selectedProjects.value[index].id,
    project_name: selectedProjects.value[index].name,
    dialog_position: position,
  }
  openFollowUpPopup({
    popup: MILESTONE_POPUPS.CALENDAR_WEEK,
    data: calendarData,
  })
}

const openNextProject = () => {
  const nextIndex =
    indexOfCurrentProject.value === selectedProjects.value.length - 1
      ? 0
      : indexOfCurrentProject.value + 1
  switchToAnotherProjectCalendarWeekPopup(nextIndex, 'left')
}

const openPrevProject = () => {
  const prevIndex =
    indexOfCurrentProject.value === 0
      ? selectedProjects.value.length - 1
      : indexOfCurrentProject.value - 1
  switchToAnotherProjectCalendarWeekPopup(prevIndex, 'right')
}
</script>

<style scoped lang="scss">
.loader-height {
  height: 70vh;
}

.editor-text {
  &:deep(a) {
    @apply text-key-500 underline;
  }

  &:deep(ol),
  &:deep(ul) {
    padding-left: 24px;
  }

  &:deep(ol li) {
    list-style-type: decimal;
    list-style-position: inside;
  }

  &:deep(ul li) {
    list-style-type: disc;
    list-style-position: inside;
    &.ql-indent-1 {
      padding-left: 3.5rem;
    }
    &.ql-indent-2 {
      padding-left: 6.5rem;
    }
    &.ql-indent-3 {
      padding-left: 9.5rem;
    }
    &.ql-indent-4 {
      padding-left: 12.5rem;
    }
    &.ql-indent-5 {
      padding-left: 15.5rem;
    }
  }
}
</style>
